import "bootstrap/js/dist/base-component";


// Sidebar

jQuery(function ($) {
    $(".sidebar__wrapper--open").on("touchend click", function () {
        var menu = $(this).attr("data-menu");


        $(menu).toggleClass("js-menu__expanded");
        $(menu).parent().toggleClass("js-menu__expanded");


    });

    $(".sidebar__wrapper, .sidebar__wrapper--close").on(
        "touchend click",
        function (event) {
            if (
                $(event.target).hasClass("sidebar__wrapper") ||
                $(event.target).hasClass("sidebar__wrapper--close")
                
            ) {

                $(".js-menu__expanded").removeClass("js-menu__expanded");
            }
        }
    );
});

// Sticky
$(function () {
    var pageScroll = 200;
    $(window).scroll(function () {
        var scroll = getCurrentScroll();
        if (scroll >= pageScroll) {
            $("header").addClass("sticky");
        } else {
            $("header").removeClass("sticky");
        }
    });

    function getCurrentScroll() {
        return window.pageYOffset || document.documentElement.scrollTop;
    }
});







$(document).ready(function() {
  // Settings:
  var drawer = '[data-swipe-drawer]';
  var panel = '[data-swipe-panel]';
  var overlay = '[data-swipe-overlay]';
  var noScrollClass = 'no-scroll';
  var flickThreshold = 20;
  
  // Initialise variables
  var openClass = $(drawer).data('swipe-open-class');
  var width = 0;
  var posStart = 0;
  var translateStart = 0;
  var diff = 0;
  var xPos = 0;
  var longTouch = true;

  $(drawer).on('touchstart', function(ts) {
    xPos = 0;
    width = $(this).children(panel).width();
    posStart = ts.originalEvent.touches[0].pageX;
    
    // get the current translate value of the panel based on it's open-state
    translateStart = -width;
    if ($(this).hasClass(openClass)) {
      translateStart = 0;
    };
    
    // set timer for "flicking"
    longTouch = false;
    setTimeout(function() {
      longTouch = true;
    }, 250);

    // Make drawer visible while interacting
    $(this).css('visibility', 'visible');
  });

  $(drawer).on('touchmove', function(tm) {
    xPos = tm.originalEvent.touches[0].pageX - posStart;
    diff = translateStart + xPos;
    
    // Set styles depending on how far the touchmove moves
    if (diff >= -width && diff <= 0) {
      $(this).children(panel).css({
        'transform': 'translate3d(' + diff + 'px,0,0)',
        'transition-duration': '0s'
      });
      $(this).children(overlay).css({
        'opacity': 1 - (-1 / width * diff),
        'transition-duration': '0s'
      });
    }
  });

  $(drawer).on('touchend', function() {
    // Determine flick
    var flickClose = false;
    var flickOpen = false;
    if (!longTouch) {
      if (xPos > flickThreshold) {
        flickOpen = true;
      } else if (xPos < -flickThreshold) {
        flickClose = true;
      }
    }
    
    // Determine if drawer should be open
    if (flickOpen || xPos >= width/2) {
      $(this).addClass(openClass);
      $('body').addClass(noScrollClass);
    } else if (flickClose || xPos < -width/2) {
      $(this).removeClass(openClass);
      $('body').removeClass(noScrollClass);
    }
    
    // Remove inline css from js animation
    $(this).removeAttr('style');
    $(this).children(panel).removeAttr('style');
    $(this).children(overlay).removeAttr('style');
  });

  $('[data-swipe-overlay], [data-swipe-close]').on('click', function(e) {
    e.preventDefault();
    $(drawer).removeClass(openClass);
    $('body').removeClass(noScrollClass);
  });
  
  $('[data-swipe-open]').on('click', function(e) {
    e.preventDefault();
    $(drawer).addClass(openClass);
    $('body').addClass(noScrollClass);
  });
});